import React from "react";
import * as Chakra from "@chakra-ui/react";
import { Link, PageProps } from "gatsby";
import { Seo } from "../components/seo";
import EmailNewsletterOptions from "../components/email-newsletter/email-newsletter-options";
import { LivePreviewWindow } from "../components/live-preview-window";
import { EmailNewsletterMockup } from "../components/email-newsletter/email-newsletter-mockup";
import NextPageButton from "../components/next-page-button";
import {
  AnimationState,
  useAnimatableLayoutEffect,
} from "../components/animation-helpers/animation-state";

const EmailNewsletter = ({ location }: PageProps): React.ReactElement => {
  const [currentScale, setCurrentScale] = React.useState(1);

  useAnimatableLayoutEffect(() => {
    if (typeof window !== "undefined") {
      const vh = AnimationState.getViewportHeight();
      setCurrentScale(vh / 1130);
    }
  }, []);

  return (
    <Chakra.Box width="100%">
      <Seo title="Email Newsletter" location={location} />
      <Chakra.Flex
        width="100%"
        flexDir={{ base: "column", lg: "row" }}
        align="start"
        justify="center"
        position="sticky"
        top="0"
        bottom="0"
        left="0"
        right="0"
        overflowY="hidden"
        height="100%"
      >
        <EmailNewsletterOptions />
        <Chakra.Box
          width="100%"
          m="auto"
          pb={{ base: "50px", lg: "0" }}
          px={{ base: "20px", lg: "0" }}
        >
          <LivePreviewWindow maxWidth="600px" m="auto">
            <EmailNewsletterMockup scale={currentScale} />
          </LivePreviewWindow>
        </Chakra.Box>
      </Chakra.Flex>
      <NextPageButton as={Link} to="/landing-page">
        Next Step
      </NextPageButton>
    </Chakra.Box>
  );
};

export default EmailNewsletter;
