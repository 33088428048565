import React from "react";
import * as Chakra from "@chakra-ui/react";
import PageScrollButton from "../page-scroll-button";
import { HeadingMedium } from "../heading-medium";
import { BodyText } from "../body-text";
import { FormScrollArea } from "../form-scroll-area";
import ImageInfo from "../image-info";
import BodyInputText from "../body-input-text";
import BodyInputTextarea from "../body-input-textarea";
import BulletOptions from "./bullet-options";
import EmailOptions from "./email-options";
import EmailNewsletterState from "./email-newsletter-state-machine";

const EmailNewsletterOptions = () => {
  const scrollAreaRef = React.useRef<HTMLDivElement>();

  const valuePropositionValue = EmailNewsletterState.useGlobalState(
    (gs) => gs.valueProposition
  );
  const bodyCopyValue = EmailNewsletterState.useGlobalState(
    (gs) => gs.bodyCopy
  );
  const bodySignOffValue = EmailNewsletterState.useGlobalState(
    (gs) => gs.bodySignOff
  );
  const dotPointValues = EmailNewsletterState.useGlobalState(
    (gs) => gs.dotPoints
  );
  const buttonCtaValue = EmailNewsletterState.useGlobalState(
    (gs) => gs.buttonCta
  );

  return (
    <>
      <Chakra.Box width="100%" height="100%">
        <Chakra.VStack
          justify="start"
          align="start"
          width="100%"
          height="100%"
          py={{ base: "20px", lg: "80px" }}
          pl={{ base: "20px", lg: "82px" }}
          pr={{ base: "20px", lg: "0" }}
        >
          <FormScrollArea ref={scrollAreaRef}>
            <HeadingMedium>Email Newsletter</HeadingMedium>
            <BodyText>
              Email newsletters inform customers about the latest news, tips or
              updates about your product or company. There’s no end to what you
              can include in an email newsletter, and they’re a great way to
              communicate and test new product ideas with your audience.
            </BodyText>
            <EmailOptions
              title="Subject Line"
              body="A clear and concise message that will generate interest with your audience."
              inputs={[
                { placeholder: "Enter Subject", number: "" },
                { placeholder: "Enter Pre header line", number: "" },
              ]}
            />
            <ImageInfo
              title="Cover Image"
              text="Compelling images will encourage your target audience to engage. Recommended image size: 1,200 x 628 pixels."
              onChange={EmailNewsletterState.setCoverImage}
            />
            <BodyInputText
              title="Value Proposition"
              body="A simple statement highlighting why customers should choose your product."
              placeholder="Please Enter"
              number={60}
              value={valuePropositionValue}
              onChange={(e) =>
                EmailNewsletterState.setValueProposition(e.target.value, 60)
              }
            />
            <BodyInputTextarea
              title="Body Copy"
              body="Provide more detail to connect the audience with the value proposition."
              placeholder="Message"
              number={400}
              value={bodyCopyValue}
              onChange={(e) => EmailNewsletterState.setBodyCopy(e, 400)}
            />
            <BulletOptions
              title="Benefits"
              body="Outline the key penefits of your product or offer."
              inputs={[
                { placeholder: "Enter Header", number: 50 },
                { placeholder: "Enter Header", number: 50 },
                { placeholder: "Enter Header", number: 50 },
                { placeholder: "Enter Header", number: 50 },
              ]}
              values={dotPointValues}
              onChange={EmailNewsletterState.setDotPoint}
            />
            <BodyInputTextarea
              title="Copy Sign Off"
              body="Summarise your message. Leave your audience on a positive note."
              placeholder="Message"
              number={400}
              value={bodySignOffValue}
              onChange={(e) => EmailNewsletterState.setBodySignOff(e, 400)}
            />
            <BodyInputText
              title="Button CTA"
              body="Encourage people to click with an explicit call to action button."
              placeholder="Button Text"
              value={buttonCtaValue}
              onChange={(e) =>
                EmailNewsletterState.setButtonCta(e.target.value)
              }
            />
          </FormScrollArea>
          <PageScrollButton scrollArea={scrollAreaRef} />
        </Chakra.VStack>
      </Chakra.Box>
    </>
  );
};
export default EmailNewsletterOptions;
