import React from "react";
import * as Chakra from "@chakra-ui/react";

const EmailOptions = ({ title, body, inputs }) => {
  return (
    <Chakra.Box width="100%">
      <Chakra.Text
        fontSize="14px"
        fontWeight="500"
        lineHeight="20px"
        letterSpacing="0.11px"
        color="#000"
      >
        {title}
      </Chakra.Text>
      <Chakra.Text
        fontSize="14px"
        fontWeight="300"
        lineHeight="20px"
        letterSpacing="0.11px"
        color="#000"
        pb="35px"
        maxWidth="480px"
      >
        {body}
      </Chakra.Text>
      <Chakra.Grid templateColumns="repeat(1, 1fr)" gap={6}>
        {inputs.map((input) => (
          <Chakra.InputGroup width="100%">
            <Chakra.Input
              placeholder={input.placeholder}
              width="100%"
              borderRadius="2px"
              height="40px"
              borderWidth="1px"
              borderStyle="solid"
              borderColor="#bababa"
              _placeholder={{
                fontSize: "14px",
                color: "#000",
              }}
            />
            <Chakra.InputRightElement
              children={
                <Chakra.Text fontSize="13px" color="#c8232a">
                  {input.number}
                </Chakra.Text>
              }
            />
          </Chakra.InputGroup>
        ))}
      </Chakra.Grid>
    </Chakra.Box>
  );
};

export default EmailOptions;
